<template>
  <div class="wrapper">
    <b-container>
      <b-row>
        <h3>Contact us</h3>
      </b-row>
  
      <p class="my-4" align="start"> <b>Address:</b> 45 S Arroyo Pkwy, Pasadena, CA 91105, United States</p>

      <div align="start">
        <b-form @submit="onSubmit" v-if="show">
          <b-row>
            <b-col md="6" lg="4">
              <b-form-group
                id="input-group-1"
                label="Email address:"
                label-for="input-1"
                description="We'll never share your email with anyone else."
              >
                <b-form-input
                  id="input-1"
                  v-model="form.email"
                  type="email"
                  placeholder="Enter email"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Your Name:"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="form.name"
                  placeholder="Enter name"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-3"
                label="Phone number:"
                label-for="input-3"
              >
                <b-form-input
                  id="input-3"
                  v-model="form.phone"
                  placeholder="Enter number"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-3"
                label="CV/Biography:"
                label-for="input-3"
              >
                <b-form-file
                  v-model="form.file"
                  class="mt-3"
                  plain
                ></b-form-file>
              </b-form-group>
            </b-col>

            <b-col class="text-area" md="6" lg="5">
              <b-form-textarea
                id="text-area-default"
                v-model="form.textarea"
                rows="9"
                placeholder="Type text here..."
              ></b-form-textarea>
            </b-col>
          </b-row>

          <b-button type="submit" variant="outline-dark">Submit</b-button>
        </b-form>
     
        <b-modal ref="my-modal-success" id="modal-footer-sm" title="Success" button-size="sm">
          <p class="my-2">Email has been sent. We will contact to you soon. Thank You!</p>
        </b-modal>        
        <b-modal ref="my-modal-error" id="modal-footer-sm" title="Error" button-size="sm">
          <p class="my-2">Oops, something went wrong, please try again.</p>
        </b-modal>
      </div>
    </b-container>
  </div>
</template>

<script>

export default {
  data() {
    return {
      form: {
        email: "",
        name: "",
        phone: "",
        file: null,
        path: null,
        textarea: ""
      },
      show: true,
    };
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();

      let payload = { 
        name: this.form.name,
        email: this.form.email,
        phone: this.form.phone,
        text: this.form.textarea
      };

      this.axios.post('http://distinguishedsociety.com/post.php', payload)
        .then( () => {
          this.$refs['my-modal-success'].show();

          this.form.email = "";
          this.form.name = "";
          this.form.phone = "";
          this.form.textarea = "";
          // Trick to reset/clear native browser form validation state
          this.show = false;
          this.$nextTick(() => {
            this.show = true;
          });
          setTimeout(() => {
            this.$refs['my-modal-success'].hide();
          }, 3000);
        })
        .catch(() => {
          this.$refs['my-modal-error'].show();
          setTimeout(() => {
            this.$refs['my-modal-error'].hide();
          }, 3000);
        });
    }
  },
};
</script>

<style scoped>
.wrapper {
  position: relative;
  z-index: 1;
  overflow: hidden;
  min-height: 90%;
}

.wrapper::after {
  content: "";
  z-index: -1;
  position: absolute;
  background-image: url("../assets/contact-blured.png");
  background-repeat: no-repeat;
  background-position: right 5% bottom 5%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-size: 700px;
  opacity: 0.6;
}

.container > .row {
  margin: 80px 0;
}

.text-area {
  padding-top: 35px;
}

.text-area textarea{
  padding-bottom: 15px;
}

.btn {
  margin: 3rem 0;
}

@media screen and (min-width: 1720px) {
  .wrapper::after {
    background-size: 40%;
  }
}
</style>